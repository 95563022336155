import produce from 'immer';
import { PassKeyAction, PassKeyActionTypes } from './actions';
import { User } from '@raumplan/domain';
export interface PassKeyState {
  users: User[];
  status: 'loading' | 'success' | 'error';
}

export const initState: PassKeyState = {
  users: [],
  status: 'loading',
};

export const reducer = (state: PassKeyState, action: PassKeyAction) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case PassKeyActionTypes.REQUEST_USERS: {
        draft.status = 'loading';
        break;
      }
      case PassKeyActionTypes.USERS_SUCCESS: {
        draft.status = 'success';
        draft.users = action.users;
        break;
      }
      case PassKeyActionTypes.USERS_ERROR: {
        draft.status = 'error';
        break;
      }
      case PassKeyActionTypes.USER_UPDATED: {
        const idx = draft.users.findIndex(
          (u) => u.account_id === action.user.account_id,
        );
        if (idx !== -1) {
          draft.users[idx] = action.user;
        }
        break;
      }
      default:
        throw new Error('unsupported action type');
    }
  });
};
