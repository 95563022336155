import { SelectUserModal } from '../SelectUserModal';
import { Action, User } from '@raumplan/domain';
import {
  Button,
  Menu,
  Fade,
  MenuItem,
  Typography,
  ListItemIcon,
  MenuList,
} from '@material-ui/core';
import { useReducer, useState } from 'react';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { initState, reducer } from './reducer';
import {
  loadingUsersError,
  loadingUsersSuccess,
  requestingUsers,
  userUpdated,
} from './actions';
import { API } from 'src/common/api/API';
import { Can } from 'src/common/context/UserAbilityProvider';
import { useAuth } from 'src/common/context/AuthProvider';
export interface Props {
  disabled?: boolean;
  onUserUpdate?: (user: User) => any;
  onLogout?: () => any;
}

export const AppMenu = ({
  disabled = false,
  onUserUpdate,
  onLogout,
}: Props) => {
  const [userState] = useAuth();
  const [state, dispatch] = useReducer(reducer, initState);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [keyPassModal, setKeyPassModalVisibility] = useState(false);
  const [editKeyModal, setEditKeyModalVisibility] = useState(false);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAll = () => {
    setKeyPassModalVisibility(false);
    setEditKeyModalVisibility(false);
    setAnchorEl(null);
  };
  const handleLoadModal = (visibilityFn: () => any) => {
    dispatch(requestingUsers());
    API.getUsers(
      (users) => {
        dispatch(loadingUsersSuccess(users));
        visibilityFn();
      },
      (e) => {
        dispatch(loadingUsersError());
        alert('could not load users');
      },
    );
  };

  const handleUserUpdate = (user: User) => {
    API.updateUser(
      { ...user, hasKey: !user.hasKey },
      (u) => {
        dispatch(userUpdated(u));
        onUserUpdate?.(u);
      },
      (e) => {
        alert('could not update user');
      },
    );
  };
  const handlePassKey = (user: User) => {
    API.passKey(
      user,
      (updatedUsers) => {
        updatedUsers.forEach((u) => onUserUpdate?.(u));
      },
      (e) => alert('could not pass key' + e.message),
    );
    handleCloseAll();
  };
  const handleLogout = () => {
    onLogout?.();
    handleCloseAll();
  };

  return (
    <div>
      <Button
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleMenuClick}
      >
        <MenuIcon />
      </Button>
      <Menu
        id="fade-menu"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleCloseAll}
        TransitionComponent={Fade}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuList>
          {userState.user?.hasKey ? (
            <MenuItem
              disabled={disabled}
              onClick={() =>
                handleLoadModal(() => setKeyPassModalVisibility(true))
              }
              aria-label="pass-key"
            >
              <ListItemIcon>
                <VpnKeyIcon />
              </ListItemIcon>
              <Typography>Pass key</Typography>
            </MenuItem>
          ) : null}
          <MenuItem
            disabled={disabled}
            onClick={() => {
              if (userState.user) handleUserUpdate(userState.user);
              handleCloseAll();
            }}
            aria-label="change-key-status"
          >
            <ListItemIcon>
              <VpnKeyIcon />
            </ListItemIcon>
            {userState.user?.hasKey ? (
              <Typography>I don't have a key</Typography>
            ) : (
              <Typography>I have a key!</Typography>
            )}
          </MenuItem>
          <Can I={Action.Manage} a="User">
            <MenuItem
              disabled={disabled}
              onClick={() =>
                handleLoadModal(() => setEditKeyModalVisibility(true))
              }
              aria-label="manage-key"
            >
              <ListItemIcon>
                <VpnKeyIcon />
              </ListItemIcon>
              <Typography>Manage keys</Typography>
            </MenuItem>
          </Can>
          <MenuItem onClick={handleLogout} aria-label="logout">
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <Typography>Logout</Typography>
          </MenuItem>
        </MenuList>
      </Menu>
      <SelectUserModal
        title="Select user to pass key"
        users={state.users}
        open={keyPassModal}
        loading={state.status === 'loading'}
        disableFn={(u) => u.hasKey}
        action={
          <Button color="secondary" onClick={() => handleCloseAll()}>
            Close
          </Button>
        }
        onClose={handleCloseAll}
        onSelect={handlePassKey}
      />
      <SelectUserModal
        title="Edit key holders"
        users={state.users}
        open={editKeyModal}
        loading={state.status === 'loading'}
        action={
          <Button color="secondary" onClick={() => handleCloseAll()}>
            Close
          </Button>
        }
        onClose={handleCloseAll}
        onSelect={handleUserUpdate}
      />
    </div>
  );
};
