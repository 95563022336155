import { Box, CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
export type Props = {
  text?: string;
};

export const LoadingScreen = ({ text = 'Loading ...' }: Props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
    >
      <CircularProgress color="secondary" />
      <Typography color="secondary">{text}</Typography>
    </Box>
  );
};
