import { CalendarPage } from './components/pages/Calendar';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './common/router/PrivateRouter';
import { LoginCallback } from './common/router/LoginCallback';
import { Welcome } from './components/molecules/Welcome';
import { AuthProvider } from './common/context/AuthProvider';
import { CssBaseline, ThemeProvider, useMediaQuery } from '@material-ui/core';
import { theme } from './theme';
import { UserAbilityProvider } from './common/context/UserAbilityProvider';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <PrivateRoute exact path="/" component={CalendarPage} />
      <Route exact path="/login" component={Welcome} />
      <Route path="/callback" component={LoginCallback} />
    </Switch>
  </BrowserRouter>
);
export const App = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  return (
    <ThemeProvider theme={theme(prefersDarkMode ? 'dark' : 'light')}>
      <CssBaseline />
      <AuthProvider>
        <UserAbilityProvider>
          <Routes />
        </UserAbilityProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};
