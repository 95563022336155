import React from 'react';

import {
  Avatar,
  Badge,
  CircularProgress,
  createStyles,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  withStyles,
} from '@material-ui/core';
import { User } from '@raumplan/domain';
import CancelIcon from '@material-ui/icons/Cancel';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

export type Props = {
  user: User;
  hasKey?: boolean;
  button?: boolean;
  disabled?: boolean;
  canDelete?: boolean;
  isDeleting?: boolean;
  onClick?: (user: User) => void;
  onDelete?: (user: User) => void;
};

const KeyBadge = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 22,
      height: 22,
      fill: '#FEC601',
      stroke: '#353535',
      border: `1px solid ${theme.palette.background.paper}`,
      borderRadius: '50%',
      backgroundColor: '#07ED7A',
    },
  }),
)(VpnKeyIcon);
const SecondaryAction = withStyles((theme: Theme) =>
  createStyles({
    root: {
      right: theme.spacing(0.5),
    },
  }),
)(ListItemSecondaryAction);
const Text = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      paddingRight: theme.spacing(3),
    },
  }),
)(ListItemText);
export const UserListItem = (props: Props) => {
  const {
    user,
    button = false,
    hasKey = false,
    disabled = false,
    canDelete = false,
    isDeleting,
    onClick,
    onDelete,
  } = props;
  const handleItemClick = () => {
    if (!disabled) {
      onClick?.(user);
    }
  };
  const avatar = (
    <ListItemAvatar>
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={hasKey ? <KeyBadge aria-label="has-key" /> : null}
      >
        <Avatar src={user.picture} />
      </Badge>
    </ListItemAvatar>
  );
  const text = (
    <Text primaryTypographyProps={{ noWrap: true }}>{user.name}</Text>
  );
  const secondaryIcon = isDeleting ? (
    <CircularProgress color="secondary" size={24} />
  ) : (
    <CancelIcon />
  );
  const secondaryActions = canDelete ? (
    <SecondaryAction>
      <IconButton
        id={'user-button-' + user.account_id}
        color="secondary"
        disabled={isDeleting}
        aria-label="user-delete"
        onClick={() => onDelete?.(user)}
      >
        {secondaryIcon}
      </IconButton>
    </SecondaryAction>
  ) : null;
  const content = (
    <>
      {avatar}
      {text}
      {secondaryActions}
    </>
  );
  return button ? (
    <ListItem
      role="listitem"
      button
      disabled={disabled}
      onClick={handleItemClick}
    >
      {content}
    </ListItem>
  ) : (
    <ListItem role="listitem" disabled={disabled}>
      {content}
    </ListItem>
  );
};
