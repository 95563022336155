import { Entry, Calendar, User } from '@raumplan/domain';

export enum MainActionTypes {
  ADD_ENTRY = 'entry/add',
  DELETE_ENTRY = 'entry/delete',
  LOADING_CALENDAR = 'calendar/load',
  LOAD_CALENDAR_SUCCESS = 'calendar/load/success',
  LOAD_CALENDAR_FAIL = 'calendar/load/fail',
  SELECT_CALENDAR = 'calendar/select',
  UNSELECT_CALENDAR = 'calendar/unselect',
  LOGGING_IN = 'logging',
  LOGIN_SUCCESS = 'login/success',
  LOGIN_FAILED = 'login/fail',
  LOGOUT = 'logout',
  USER_UPDATE = 'user/update',
}

export interface AddEntryAction {
  type: MainActionTypes.ADD_ENTRY;
  calId: number;
  entry: Entry;
}

export interface DeleteEntryAction {
  type: MainActionTypes.DELETE_ENTRY;
  calId: number;
  entryId: number;
}

export interface LoadingCalendarAction {
  type: MainActionTypes.LOADING_CALENDAR;
  calId: number;
}

export interface LoadCalendarSuccessAction {
  type: MainActionTypes.LOAD_CALENDAR_SUCCESS;
  calendar: Calendar;
}

export interface LoadCalendarFailAction {
  type: MainActionTypes.LOAD_CALENDAR_FAIL;
  calId: number;
  error: Error;
}

export interface SelectCalendarAction {
  type: MainActionTypes.SELECT_CALENDAR;
  calendar: Calendar;
}
export interface UnselectCalendarAction {
  type: MainActionTypes.UNSELECT_CALENDAR;
}
export interface UserUpdatedAction {
  type: MainActionTypes.USER_UPDATE;
  user: User;
}

export function addEntry(calId: number, entry: Entry): AddEntryAction {
  return {
    type: MainActionTypes.ADD_ENTRY,
    calId,
    entry,
  };
}

export function deleteEntry(calId: number, entryId: number): DeleteEntryAction {
  return {
    type: MainActionTypes.DELETE_ENTRY,
    calId,
    entryId,
  };
}

export function loadCalendar(calId: number): LoadingCalendarAction {
  return {
    type: MainActionTypes.LOADING_CALENDAR,
    calId,
  };
}

export function loadCalendarFail(
  calId: number,
  error: Error,
): LoadCalendarFailAction {
  return {
    type: MainActionTypes.LOAD_CALENDAR_FAIL,
    calId: calId,
    error,
  };
}

export function loadCalendarSuccess(
  calendar: Calendar,
): LoadCalendarSuccessAction {
  return { type: MainActionTypes.LOAD_CALENDAR_SUCCESS, calendar };
}
export function selectCalendar(calendar: Calendar): SelectCalendarAction {
  return { type: MainActionTypes.SELECT_CALENDAR, calendar };
}
export function unselectCalendar(): UnselectCalendarAction {
  return { type: MainActionTypes.UNSELECT_CALENDAR };
}
export function userUpdated(user: User): UserUpdatedAction {
  return { type: MainActionTypes.USER_UPDATE, user };
}
export type CalendarAction =
  | AddEntryAction
  | DeleteEntryAction
  | LoadingCalendarAction
  | LoadCalendarFailAction
  | LoadCalendarSuccessAction
  | SelectCalendarAction
  | UnselectCalendarAction
  | UserUpdatedAction;
