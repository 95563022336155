import React from 'react';
import { Icon, makeStyles } from '@material-ui/core';
import { ReactComponent as Logo } from './qupe-logo.svg';

export const QupeIcon = () => {
  const useStyles = makeStyles({
    imageIcon: {
      height: '80%',
      width: '80%',
    },
    iconRoot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'inherit',
      width: 'inherit',
    },
  });
  const classes = useStyles();
  return (
    <Icon classes={{ root: classes.iconRoot }}>
      <Logo className={classes.imageIcon} />
    </Icon>
  );
};
