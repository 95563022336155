import axios, { AxiosError } from 'axios';
import { bearerInterceptor } from '../interceptor';

import { AccessToken, CreateCalendarDTO, CreateEntryDTO } from '../models';
import { Calendar, User, Entry } from '@raumplan/domain';
import { handleDates } from '../date-interceptor';

/**
 * Service provides access to backend functionalities.
 * It uses html calls to communicate requests.
 */
export class Service {
  constructor() {
    axios.interceptors.response.use((originalResponse) => {
      handleDates(originalResponse.data);
      return originalResponse;
    });
  }
  /**
   * Id used to know if an interceptor was added to axios
   * When value is present, interceptor is present in axios client
   */
  private activeAuthInterceptor: number | null = null;
  /**
   * Get a list of calendars from backend
   * @param onSuccess is called with list of calendars retrieved.
   * @param onFailure is called if an error happens during communication.
   */
  getCalendars(
    onSuccess: (cals: Calendar[]) => any,
    onFailure: (err: Error) => any,
  ) {
    axios
      .get<Calendar[]>('/v1/calendars')
      .then((resp) => {
        onSuccess(resp.data ? resp.data : []);
      })
      .catch((e: AxiosError) => onFailure(e));
  }
  /**
   * Obtains a calendar from backend
   * @param id of calendar to get.
   * @param onSuccess called with calendar if found.
   * @param onFailure called if not found or an error happens during communication.
   */
  getCalendar(
    id: number,
    onSuccess: (cals: Calendar) => any,
    onFailure: (err: Error) => any,
  ) {
    axios
      .get<Calendar>(`/v1/calendars/${id}`)
      .then((resp) => onSuccess(resp.data))
      .catch((e: AxiosError) => onFailure(e));
  }
  /**
   * Creates a calendar in backend.
   * @param dto calendar details.
   * @param onSuccess called if calendar creation was successful with created calendar.
   * @param onFailure called when cant create the calendar or an error happens during communication.
   */
  createCalendar(
    dto: CreateCalendarDTO,
    onSuccess: (cal: Calendar) => any,
    onFailure: (err: Error) => any,
  ) {
    axios
      .post<Calendar>('/v1/calendars', dto)
      .then((resp) => onSuccess(resp.data))
      .catch((e: AxiosError) => onFailure(e));
  }
  /**
   * Deletes a calendar
   * @param id calendar id to delete
   * @param onSuccess called if calendar deletion was successful.
   * @param onFailure called when cant delete the calendar or an error happens during communication.
   */
  deleteCalendar(
    id: number,
    onSuccess: () => any,
    onFailure: (err: Error) => any,
  ) {
    axios
      .delete<Calendar>(`/v1/calendars/${id}`)
      .then(() => onSuccess())
      .catch((e: AxiosError) => onFailure(e));
  }

  /**
   * Add a new entry to a calendar
   * @param calendarId calendar to add entry
   * @param dto entry details
   * @param onSuccess called if entry could be created in backend pass new entry values.
   * @param onFailure called if could not create entry or an error happens during communication.
   */
  addEntry(
    calendarId: number,
    dto: CreateEntryDTO,
    onSuccess: (entry: Entry) => any,
    onFailure: (err: Error) => any,
  ) {
    axios
      .post<Entry>(`/v1/calendars/${calendarId}/entries/`, dto)
      .then((resp) => onSuccess(resp.data))
      .catch((e: AxiosError) => onFailure(e));
  }

  /**
   * Deletes a calendars entry. It checks requested entry belongs to given calendar.
   * @param calendarId calendar to delete entry
   * @param entryId id of entry
   * @param onSuccess called if entry was successfully deleted
   * @param onFailure if calendar/entry not found or error happen during communication.
   */
  deleteEntry(
    calendarId: number,
    entryId: number,
    onSuccess: () => any,
    onFailure: (err: Error) => any,
  ) {
    axios
      .delete(`/v1/calendars/${calendarId}/entries/${entryId}`)
      .then(() => onSuccess())
      .catch((e: AxiosError) => onFailure(e));
  }

  /**
   * Ask backend to issue a token for the current user
   * using the code given back from atlassian oauth service
   * @param code code obtained from atlassian oauth login
   * @param onSuccess called with access token when token request succeded
   * @param onFailure called if an error happens during token request.
   */
  getAtlassianToken(
    code: string,
    onSuccess: (result: AccessToken) => any,
    onFailure: (error: Error) => any,
  ) {
    axios
      .get<AccessToken>(`/v1/auth/atlassian?code=${code}`)
      .then((resp) => onSuccess(resp.data))
      .catch((e: AxiosError) => onFailure(e));
  }
  /**
   * Obtains user details given a session token
   * @param token session token
   * @param onSuccess called with user object if request is successfull
   * @param onFailure called if request fails
   */
  getSessionUser(
    onSuccess: (result: User) => any,
    onFailure: (error: Error) => any,
  ) {
    axios
      .get<User>('/v1/users/me')
      .then((resp) => onSuccess(resp.data))
      .catch((e: AxiosError) => onFailure(e));
  }
  /**
   * Obtains user details given a session token
   * @param token session token
   * @param onSuccess called with user object if request is successfull
   * @param onFailure called if request fails
   */
  getUsers(
    onSuccess: (result: User[]) => any,
    onFailure: (error: Error) => any,
  ) {
    axios
      .get<User[]>('/v1/users')
      .then((resp) => onSuccess(resp.data))
      .catch((e: AxiosError) => onFailure(e));
  }
  /**
   * Update server information about user given account_id
   * @param user object with updated data
   * @param onSuccess called with user object if request is successfull
   * @param onFailure called if request fails
   */
  updateUser(
    user: User,
    onSuccess: (result: User) => any,
    onFailure: (error: Error) => any,
  ) {
    axios
      .patch<User>(`/v1/users/${user.account_id}`, user)
      .then((resp) => onSuccess(resp.data))
      .catch((e) => onFailure(e));
  }

  /**
   * Passes the key to another user, update happens on backend.
   * As side effect all entries assigned to changed users are updated.
   */
  passKey(
    user: User,
    onSuccess: (updated: User[]) => any,
    onFailure: (error: Error) => any,
  ) {
    axios
      .post<[User, User]>(`/v1/users/${user.account_id}/passkey`)
      .then((u) => onSuccess(u.data))
      .catch((e: AxiosError) => onFailure(e));
  }
  /**
   * Adds the provided access token to all of API calls done using axios
   * @param access_token authorization token provided by backend
   */
  useBearerAuth(access_token: string): void {
    if (this.activeAuthInterceptor) {
      return; //will not override interceptor
    }
    this.activeAuthInterceptor = axios.interceptors.request.use(
      bearerInterceptor(access_token),
    );
  }
  /**
   * Stops using current access token (if previouslyprovided)
   */
  discardBearerAuth(): void {
    if (this.activeAuthInterceptor !== null) {
      axios.interceptors.request.eject(this.activeAuthInterceptor);
      this.activeAuthInterceptor = null;
    }
  }
}
export const API = new Service();
