import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { Can } from '../../../common/context/UserAbilityProvider';
import { Action } from '@raumplan/domain';

export type Props = {
  id: number;
  name: string;
  isSelected: boolean;
  onDelete?: (id: number) => any;
  onSelect?: (id: number) => any;
};

export const CalendarListItem = ({
  id,
  name,
  isSelected,
  onDelete,
  onSelect,
}: Props) => {
  const handleDelete = () => onDelete?.(id);
  const handleSelect = () => onSelect?.(id);
  return (
    <ListItem
      key={id}
      button
      role="listitem"
      onClick={handleSelect}
      selected={isSelected}
    >
      <ListItemText color="primaryText" primary={name} />
      <ListItemSecondaryAction>
        <Can I={Action.Delete} a="Calendar">
          <IconButton
            color="secondary"
            edge="end"
            aria-label="calendar-delete"
            onClick={handleDelete}
          >
            <DeleteIcon />
          </IconButton>
        </Can>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
