import produce from 'immer';
import { Calendar, User } from '@raumplan/domain';
import { CalendarAction, MainActionTypes } from './actions';
import dayjs from 'dayjs';

export enum Screen {
  ERROR = 'error',
  LOADING = 'loading',
  LOADED = 'loaded',
  NOT_SELECTED = 'not selected',
}

export interface ICalendarState {
  calendars: Calendar[];
  current?: Calendar;
  user?: User;
  isLoadingEntries: boolean;
  isLoadingCalendars: boolean;
  screen: Screen;
  error: string;
}
export const initialCalendarState: ICalendarState = {
  calendars: [],
  current: undefined,
  isLoadingEntries: false,
  isLoadingCalendars: true,
  screen: Screen.NOT_SELECTED,
  error: '',
  user: undefined,
};

export const reducer = (state: ICalendarState, action: CalendarAction) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case MainActionTypes.ADD_ENTRY: {
        if (draft.current?.entries)
          if (!draft.current.entries.find((e) => e.id === action.entry.id)) {
            draft.current.entries = [...draft.current.entries, action.entry];
          }
        break;
      }
      case MainActionTypes.DELETE_ENTRY: {
        if (draft.current?.entries) {
          const idx = draft.current.entries.findIndex(
            (e) => e.id === action.entryId,
          );
          if (idx >= 0) {
            draft.current.entries.splice(idx, 1);
          }
        }
        break;
      }
      case MainActionTypes.LOADING_CALENDAR: {
        draft.isLoadingEntries = true;
        draft.screen = Screen.LOADING;
        break;
      }
      case MainActionTypes.LOAD_CALENDAR_SUCCESS: {
        draft.isLoadingEntries = false;
        draft.screen = Screen.LOADED;
        draft.current = action.calendar;
        break;
      }
      case MainActionTypes.LOAD_CALENDAR_FAIL: {
        draft.isLoadingEntries = false;
        draft.screen = Screen.ERROR;
        draft.current = undefined;
        draft.error = action.error.message;
        break;
      }
      case MainActionTypes.UNSELECT_CALENDAR: {
        draft.current = undefined;
        draft.screen = Screen.NOT_SELECTED;
        break;
      }
      case MainActionTypes.SELECT_CALENDAR: {
        draft.current = action.calendar;
        draft.screen = Screen.LOADING;
        break;
      }
      case MainActionTypes.USER_UPDATE: {
        if (draft.current) {
          draft.current.entries = draft.current.entries.map((e) => {
            const today = dayjs();
            if (
              today.isAfter(e.date, 'day') ||
              e.user.account_id !== action.user.account_id
            ) {
              return e;
            }
            return { ...e, hasKey: action.user.hasKey, user: action.user };
          });
        }
        break;
      }
      default:
        throw new Error('action not supported');
    }
  });
};
