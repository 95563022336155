import { Icon, makeStyles } from '@material-ui/core';
import React from 'react';
import logo from './atlassian-logo.svg';

export const AtlassianIcon = () => {
  const useStyles = makeStyles({
    imageIcon: {
      display: 'flex',
      height: 'inherit',
      width: 'inherit',
    },
    iconRoot: {
      textAlign: 'center',
    },
  });
  const classes = useStyles();
  return (
    <Icon classes={{ root: classes.iconRoot }}>
      <img className={classes.imageIcon} src={logo} />
    </Icon>
  );
};
