import React from 'react';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import { Can } from 'src/common/context/UserAbilityProvider';
import { Action } from '@raumplan/domain';

export type Props = {
  onRefresh?: () => any;
  onCreate?: () => any;
};
export const CalendarActionMenu = ({ onRefresh, onCreate }: Props) => {
  const useStyle = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(1),
    },
  }));
  const classes = useStyle();
  return (
    <Grid
      className={classes.root}
      container
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <Typography color="textPrimary">Calendars</Typography>
      <div>
        <Button
          aria-label="refresh"
          size="small"
          color="primary"
          onClick={() => onRefresh?.()}
        >
          <RefreshIcon />
        </Button>
        <Can I={Action.Delete} a="Calendar">
          <Button
            aria-label="add-calendar-modal"
            size="small"
            color="primary"
            onClick={() => onCreate?.()}
          >
            <AddIcon />
          </Button>
        </Can>
      </div>
    </Grid>
  );
};
