import { User } from '@raumplan/domain';

export enum PassKeyActionTypes {
  REQUEST_USERS = 'users/loading',
  USERS_SUCCESS = 'users/success',
  USERS_ERROR = 'users/error',
  USER_UPDATED = 'users/update',
}

export interface RequestUsersAction {
  type: PassKeyActionTypes.REQUEST_USERS;
}
export interface UsersSuccessAction {
  users: User[];
  type: PassKeyActionTypes.USERS_SUCCESS;
}
export interface UsersErrorAction {
  type: PassKeyActionTypes.USERS_ERROR;
}
export interface UserUpdatedAction {
  user: User;
  type: PassKeyActionTypes.USER_UPDATED;
}
export function requestingUsers(): RequestUsersAction {
  return { type: PassKeyActionTypes.REQUEST_USERS };
}
export function loadingUsersSuccess(users: User[]): UsersSuccessAction {
  return { type: PassKeyActionTypes.USERS_SUCCESS, users };
}
export function loadingUsersError(): UsersErrorAction {
  return { type: PassKeyActionTypes.USERS_ERROR };
}
export function userUpdated(user: User): UserUpdatedAction {
  return { type: PassKeyActionTypes.USER_UPDATED, user };
}
export type PassKeyAction =
  | RequestUsersAction
  | UsersSuccessAction
  | UsersErrorAction
  | UserUpdatedAction;
