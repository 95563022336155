import { Entry } from '@raumplan/domain';

export enum UpdateActionTypes {
  UPDATE_DATE = 'date/update',
  UPDATE_DATE_FINISHED = 'date/update/finished',
  UPDATE_ENTRY = 'entry/update',
  UPDATE_ENTRY_FINISHED = 'entry/update/finished',
}

export interface UpdateDateAction {
  type: UpdateActionTypes.UPDATE_DATE;
  date: Date;
}
export interface UpdateDateFinishedAction {
  type: UpdateActionTypes.UPDATE_DATE_FINISHED;
  date: Date;
}
export interface UpdateEntryAction {
  type: UpdateActionTypes.UPDATE_ENTRY;
  entry: Entry;
}
export interface UpdateEntryFinishedAction {
  type: UpdateActionTypes.UPDATE_ENTRY_FINISHED;
  entry: Entry;
}
export function updatingDate(date: Date): UpdateDateAction {
  return { type: UpdateActionTypes.UPDATE_DATE, date };
}
export function updatingDateDone(date: Date): UpdateDateFinishedAction {
  return { type: UpdateActionTypes.UPDATE_DATE_FINISHED, date };
}
export function updatingEntry(entry: Entry): UpdateEntryAction {
  return { type: UpdateActionTypes.UPDATE_ENTRY, entry };
}
export function updatingEntryFinished(entry: Entry): UpdateEntryFinishedAction {
  return { type: UpdateActionTypes.UPDATE_ENTRY_FINISHED, entry };
}
export type UpdateAction =
  | UpdateDateAction
  | UpdateDateFinishedAction
  | UpdateEntryAction
  | UpdateEntryFinishedAction;
