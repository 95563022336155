import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useHotkeys } from 'react-hotkeys-hook';
import { Box, Button, ButtonGroup, Typography } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

export type WeekPickerProps = {
  initDate?: Dayjs;
  onIncrease?: () => void;
  onDecrease?: () => void;
  onToday?: () => void;
};

export const WeekPicker = ({
  initDate,
  onIncrease,
  onDecrease,
  onToday,
}: WeekPickerProps) => {
  const increaseDate = () => onIncrease?.();
  const decreaseDate = () => onDecrease?.();
  const todayDate = () => onToday?.();

  useHotkeys('right', increaseDate);
  useHotkeys('space', todayDate);
  useHotkeys('left', decreaseDate);

  const date = initDate ? initDate : dayjs();

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="flex-end"
      justifyContent="space-between"
    >
      <Typography variant="h2">{date.format('MMMM YYYY')}</Typography>
      <ButtonGroup color="primary" variant="contained">
        <Button aria-label="Decrease Week" onClick={decreaseDate}>
          <NavigateBeforeIcon />
        </Button>
        <Button aria-label="This Week" onClick={todayDate}>
          Today
        </Button>
        <Button aria-label="Increase Week" onClick={increaseDate}>
          <NavigateNextIcon />
        </Button>
      </ButtonGroup>
    </Box>
  );
};
