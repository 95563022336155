import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import {
  Avatar,
  Box,
  Button,
  createStyles,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { User } from '@raumplan/domain';
import { QupeLogo } from 'src/components/atoms/QupeLogo';
import { AppMenu } from '../AppMenu';

export type Props = {
  title: string;
  onUserUpdate?: (user: User) => any;
  onLogout?: () => any;
  onLogin?: () => any;
  user?: User;
};
const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      padding: theme.spacing(1),
    },
    toolbar: {
      display: 'flex',
      flexWrap: 'nowrap',
      padding: theme.spacing(1),
      justifyContent: 'space-between',
      maxHeight: theme.spacing(15),
    },
    title: {
      padding: theme.spacing(1),
      textTransform: 'capitalize',
      maxWidth: '70%',
      maxHeight: '50%',
    },
    avatar: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
  }),
);
export const CalendarAppBar = ({
  title = '',
  user,
  onUserUpdate,
  onLogout,
  onLogin,
}: Props) => {
  const classes = useStyles();
  const renderUser = () => {
    if (user) {
      return (
        <>
          <Typography variant="h6" className={classes.title}>
            {user?.name}
          </Typography>
          <Avatar
            className={classes.avatar}
            alt={user?.name}
            src={user?.picture}
          />
          <AppMenu
            onLogout={() => onLogout?.()}
            onUserUpdate={(u) => onUserUpdate?.(u)}
          />
        </>
      );
    } else {
      return (
        <Button
          variant="outlined"
          color="secondary"
          className={classes.button}
          onClick={() => onLogin?.()}
        >
          Log in
        </Button>
      );
    }
  };
  return (
    <div>
      <AppBar position="sticky" color="inherit">
        <Toolbar className={classes.toolbar}>
          <QupeLogo />
          <Typography className={classes.title} variant="h3" noWrap>
            {title.charAt(0).toUpperCase() + title.slice(1)}
          </Typography>
          <Box
            display="flex"
            flex="1"
            alignItems="center"
            justifyContent="flex-end"
          >
            {renderUser()}
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
};
