import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { defineRulesForUser, Role, User } from '@raumplan/domain';
import { useAuth } from '../AuthProvider';

const defaultAbility = defineRulesForUser({
  kind: 'User',
  role: Role.User,
} as User);

interface Props {
  children?: React.ReactNode;
}
export const UserAbilityContext = createContext(defaultAbility);
export const Can = createContextualCan(UserAbilityContext.Consumer);
export const UserAbilityProvider = ({ children }: Props) => {
  const [authState] = useAuth();
  const { user, is } = authState;
  const ability =
    user && is === 'authenticated' ? defineRulesForUser(user) : defaultAbility;
  return (
    <UserAbilityContext.Provider value={ability}>
      {children}
    </UserAbilityContext.Provider>
  );
};
