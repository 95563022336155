import produce from 'immer';
import { Calendar } from '@raumplan/domain';
import { CalendarAction, CalendarActionsTypes } from './actions';

export interface CalendarSelectorState {
  calendars: Calendar[];
  selected?: Calendar;
  isLoading: boolean;
  error: string;
}

export const initialState: CalendarSelectorState = {
  calendars: [],
  isLoading: true,
  error: '',
};

export const reducer = (
  state: CalendarSelectorState,
  action: CalendarAction,
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case CalendarActionsTypes.ADD: {
        draft.calendars.push(action.calendar);
        break;
      }
      case CalendarActionsTypes.REMOVE: {
        const index = draft.calendars.findIndex(
          (cal) => cal.id === action.calendar.id,
        );
        if (index !== -1) draft.calendars.splice(index, 1);
        if (action.calendar === draft.selected) {
          draft.selected = undefined;
        }
        break;
      }
      case CalendarActionsTypes.SELECT: {
        draft.selected = action.calendar;
        break;
      }
      case CalendarActionsTypes.LOAD: {
        draft.isLoading = true;
        break;
      }
      case CalendarActionsTypes.LOAD_SUCCESSFUL: {
        draft.calendars = action.calendars;
        draft.isLoading = false;
        break;
      }
      case CalendarActionsTypes.LOAD_FAIL: {
        draft.error = action.error.message;
        break;
      }
      default:
        throw new Error('action not supported');
    }
  });
};
