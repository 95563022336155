import { Box, List } from '@material-ui/core';
import React, { useEffect, useReducer, useState } from 'react';
import { API } from 'src/common/api/API';
import {
  addCalendar,
  loadCalendarFailed,
  loadCalendars,
  loadCalendarSucceeded,
  removeCalendar,
  selectCalendar,
} from './actions';
import { CalendarActionMenu } from '../../molecules/CalendarActionMenu';
import { initialState, reducer } from './reducer';
import { CreateCalendarModal } from '../CreateCalendarModal';
import { NoCalendars } from 'src/components/molecules/NoCalendars';
import { LoadingScreen } from 'src/components/molecules/LoadingScreen';
import { Calendar } from '@raumplan/domain';
import { ActionModal } from '../ActionModal';
import { CalendarListItem } from 'src/components/molecules/CalendarListItem';

export type Props = {
  onSelect?: (calendar: Calendar) => any;
  onDelete?: (calendar: Calendar) => any;
};

export const CalendarSelector = ({ onSelect, onDelete }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showCreateDialog, setCreateDialogVisibility] = useState(false);
  const [showDeleteDialog, wantToDelete] = useState(null as Calendar | any);
  const { calendars, isLoading } = state;
  useEffect(() => {
    if (!calendars.length && isLoading) {
      handleRefresh();
    }
  }, [isLoading]);
  const handleSelect = (calendar?: Calendar) => {
    if (calendar) {
      dispatch(selectCalendar(calendar));
      onSelect?.(calendar);
    }
  };
  const handleDelete = (calendar: Calendar) => {
    API.deleteCalendar(
      calendar.id,
      () => {
        dispatch(removeCalendar(calendar));
        onDelete?.(calendar);
      },
      (e) => {
        alert(e);
      },
    );
  };
  const handleRefresh = () => {
    dispatch(loadCalendars());
    API.getCalendars(
      (cals) => {
        dispatch(loadCalendarSucceeded(cals));
        if (!state.selected) {
          handleSelect(cals[0]);
        }
      },
      (e) => {
        alert(e);
        dispatch(loadCalendarFailed(e));
      },
    );
  };
  let listContent = null;
  if (calendars.length) {
    listContent = (
      <List>
        {calendars.map((cal) => {
          return (
            <CalendarListItem
              key={`${cal.id}-${cal.name}`}
              id={cal.id}
              name={cal.name}
              isSelected={cal.id === state.selected?.id}
              onSelect={() => handleSelect(cal)}
              onDelete={() => wantToDelete(cal)}
            />
          );
        })}
      </List>
    );
  } else {
    listContent = <NoCalendars />;
  }
  if (isLoading) {
    listContent = <LoadingScreen />;
  }

  return (
    <>
      {showCreateDialog ? (
        <CreateCalendarModal
          open={showCreateDialog}
          onClose={() => setCreateDialogVisibility(false)}
          onCreate={(c) => {
            dispatch(addCalendar(c));
            setCreateDialogVisibility(false);
          }}
        />
      ) : null}
      {showDeleteDialog ? (
        <ActionModal
          open={true}
          title="Are you sure you want to delete this calendar?"
          onClose={() => wantToDelete(null)}
          onAccept={() => {
            handleDelete(showDeleteDialog);
            wantToDelete(null);
          }}
          onReject={() => wantToDelete(null)}
        />
      ) : null}
      <Box width="100%">
        <CalendarActionMenu
          onRefresh={handleRefresh}
          onCreate={() => setCreateDialogVisibility(!showCreateDialog)}
        />
      </Box>
      <Box width="100%" height="100%" overflow="scroll">
        {listContent}
      </Box>
    </>
  );
};
