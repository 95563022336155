import {
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  makeStyles,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import { API } from 'src/common/api/API';
import { CreateCalendarDTO } from 'src/common/models';
import { Calendar } from '@raumplan/domain';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  root: {
    padding: theme.spacing(3, 2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export interface Props {
  open: boolean;
  onCreate?: (calendar: Calendar) => any;
  onClose?: () => any;
}
export const CreateCalendarModal = ({ open, onCreate, onClose }: Props) => {
  const [name, setName] = useState('');
  const [capacity, setCapacity] = useState('');
  const classes = useStyles();

  const handleOnCreate = () => {
    let dto = { name } as CreateCalendarDTO;
    if (capacity.length) {
      dto = { ...dto, capacity: +capacity };
    }
    API.createCalendar(
      dto,
      (c) => onCreate?.(c),
      (e) => alert(e),
    );
  };
  return (
    <Dialog
      open={open}
      onClose={() => onClose?.()}
      aria-labelledby="create-calendar-dialog"
    >
      <DialogTitle id="create-calendar-dialog">Create a calendar</DialogTitle>
      <DialogContent>
        <TextField
          className={classes.textField}
          required
          id="name-input"
          label="name"
          placeholder="calendar name"
          color="secondary"
          onChange={(e) => setName(e.target.value)}
          defaultValue=""
        />
        <TextField
          className={classes.textField}
          id="capacity-input"
          label="capacity"
          color="secondary"
          type="number"
          helperText="leave empty for unlimited capacity"
          onChange={(e) => setCapacity(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          id="create-button"
          className={classes.button}
          onClick={handleOnCreate}
          disabled={!name.length}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
