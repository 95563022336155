import {
  Avatar,
  Box,
  Button,
  Container,
  Link,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { AtlassianIcon } from 'src/components/atoms/AtlassianIcon';
import { QupeIcon } from 'src/components/atoms/QupeIcon';
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.qupe.de/">
        Qupe GmbH
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
export const Welcome = () => {
  const useStyle = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(2),
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: 'transparent',
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: theme.spacing(3),
    },
    button: {
      width: '100%',
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyle();
  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.paper} variant="outlined">
        <Avatar className={classes.avatar} variant="rounded">
          <QupeIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Welcome to Raumplanung
        </Typography>
        <Box className={classes.body}>
          <Typography variant="body2">
            {' Access to see who is going to the office 🧐'}
          </Typography>
        </Box>
        <Button
          className={classes.button}
          variant="contained"
          startIcon={<AtlassianIcon />}
          href={process.env.REACT_APP_ATLASSIAN_REDIRECT_URL}
        >
          Continue with Atlassian
        </Button>
      </Paper>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};
