import React, { Dispatch, useEffect, useReducer } from 'react';
import { API } from '../../api/API';
import { User } from '@raumplan/domain';
import {
  loginFail,
  loginSuccess,
  requestLogout,
  requestLogin,
  AuthAction,
} from './actions';
import { AuthState, getInitAuth, initState, reducer } from './reducer';

export interface AuthHandler {
  login: (code: string | null) => void;
  refresh: (user?: User) => void;
  logout: () => void;
}

export const AuthContext = React.createContext<[AuthState, AuthHandler]>([
  initState,
  {
    login: () => true,
    refresh: () => true,
    logout: () => true,
  },
]);

export function useAuth(): [AuthState, AuthHandler] {
  return React.useContext(AuthContext);
}
interface Props {
  children?: React.ReactNode;
}

export function login(code: string | null, dispatch: Dispatch<AuthAction>) {
  if (!code) {
    dispatch(loginFail('Code not provided'));
    return;
  }
  dispatch(requestLogin());
  API.getAtlassianToken(
    code,
    (r) => {
      localStorage.setItem('authToken', r.access_token);
      API.useBearerAuth(r.access_token);
      refreshUserInfo(r.access_token, dispatch);
    },
    () => {
      dispatch(loginFail('Unauthorized user'));
    },
  );
}
export function refreshUserInfo(
  access_token: string,
  dispatch: Dispatch<AuthAction>,
  user?: User,
) {
  const token = access_token || '';
  if (!token) {
    logout(dispatch);
  }
  API.useBearerAuth(token);
  if (!user) {
    API.getSessionUser(
      (u) => dispatch(loginSuccess(token, u)),
      () => logout(dispatch),
    );
    return;
  }
  dispatch(loginSuccess(token, user));
}

export function logout(dispatch: Dispatch<AuthAction>) {
  dispatch(requestLogout());
  ['user', 'authToken'].forEach((key) => localStorage.removeItem(key));
  API.discardBearerAuth();
}
export const AuthProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, getInitAuth());
  const handler: AuthHandler = {
    login: (code: string | null) => login(code, dispatch),
    refresh: (u) => refreshUserInfo(state.token, dispatch, u),
    logout: () => logout(dispatch),
  };
  useEffect(() => {
    if (state.token) refreshUserInfo(state.token, dispatch);
  }, [state.token]);
  return (
    <AuthContext.Provider value={[state, handler]}>
      {children}
    </AuthContext.Provider>
  );
};
