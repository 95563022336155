import dayjs, { Dayjs } from 'dayjs';
import React, { useReducer, useState } from 'react';
import { API } from '../../../common/api/API';
import { WeekPicker } from '../../molecules/WeekPicker';
import { formatted, getWeek } from 'src/common/dateutils';
import { Day } from '../../molecules/Day';
import { Box, Typography } from '@material-ui/core';
import { Entry, User } from '@raumplan/domain';
import { datesInitState, reducer } from './reducer';
import {
  updatingDate,
  updatingDateDone,
  updatingEntry,
  updatingEntryFinished,
} from './actions';

export type WeekCalendarProps = {
  id: number;
  initDate?: Dayjs;
  capacity?: number;
  entries?: Entry[];
  currentUser?: User;
  onAddEntry?: (entry: Entry) => any;
  onDeleteEntry?: (entry: Entry) => any;
};
export interface EntryState extends Entry {
  isDeleting?: boolean;
}

export const WeekCalendar = ({
  id,
  initDate = dayjs(),
  capacity = Infinity,
  entries = [],
  currentUser,
  onAddEntry,
  onDeleteEntry,
}: WeekCalendarProps) => {
  const [date, setDate] = useState(initDate);
  const [state, dispatch] = useReducer(reducer, datesInitState);
  const handleAddUser = (targetDate: Date) => {
    dispatch(updatingDate(targetDate));
    API.addEntry(
      id,
      { date: targetDate },
      (newEntry) => {
        dispatch(updatingDateDone(targetDate));
        onAddEntry?.(newEntry);
      },
      (e) => {
        dispatch(updatingDateDone(targetDate));
        alert(e);
      },
    );
  };
  const handleDeleteUser = (entry: EntryState) => {
    delete entry.isDeleting;
    dispatch(updatingEntry(entry));
    API.deleteEntry(
      id,
      entry.id,
      () => {
        dispatch(updatingEntryFinished(entry));
        onDeleteEntry?.(entry);
      },
      (e) => {
        dispatch(updatingEntryFinished(entry));
        alert(e);
      },
    );
  };
  // <YYYY-MM-DD:Entry[]>
  const entryMap = entries
    .filter((entry) => date.isSame(entry.date, 'week'))
    .map<EntryState>((entry) =>
      state.entries.find((e) => entry.id === e.id)
        ? { ...entry, isDeleting: true }
        : { ...entry, isDeleting: false },
    )
    .reduce((prev, curr) => {
      const idx = formatted(curr.date);
      if (!prev.has(idx)) {
        prev.set(idx, []);
      }
      prev.get(idx)?.push(curr);
      return prev;
    }, new Map<string, EntryState[]>());
  return (
    <div>
      <WeekPicker
        initDate={date}
        onDecrease={() =>
          setDate((currentDate) => currentDate.subtract(1, 'week'))
        }
        onToday={() => setDate(dayjs())}
        onIncrease={() => setDate((currentDate) => currentDate.add(1, 'week'))}
      />
      <Box display="flex" flexDirection="row" flexWrap="wrap" marginTop="20px">
        {getWeek(date).map((day) => {
          return (
            <Box width="1/7" key={day.format('dddd')}>
              <Typography variant="h6" align="center">
                {day.format('dddd')}
              </Typography>
              <Day
                key={day.format('ddd')}
                date={day.toDate()}
                user={currentUser}
                capacity={capacity}
                loading={state.dates.find((d) => day.isSame(d)) ? true : false}
                entries={entryMap.get(formatted(day))}
                onAddEntry={handleAddUser}
                onEntryDelete={handleDeleteUser}
              />
            </Box>
          );
        })}
      </Box>
    </div>
  );
};
