import { Box, Typography } from '@material-ui/core';
import React from 'react';

export const NoCalendars = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Typography color="secondary">No calendars</Typography>
    </Box>
  );
};
