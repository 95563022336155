import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { useAuth } from 'src/common/context/AuthProvider';
import { validJwtToken } from 'src/common/Auth';

export function PrivateRoute({ component: Component, ...rest }: any) {
  const [user] = useAuth();
  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) => {
        if (validJwtToken(user.token)) {
          return <Component />;
        }
        return (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        );
      }}
    />
  );
}
