import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
} from '@material-ui/core';
import { User } from '@raumplan/domain';
import { LoadingScreen } from 'src/components/molecules/LoadingScreen';
import { UserListItem } from 'src/components/molecules/UserListItem';

export interface Props {
  open: boolean;
  title?: string;
  users: User[];
  action?: React.ReactNode;
  loading?: boolean;
  onSelect?: (user: User) => any;
  disableFn?: (user: User) => boolean;
  onClose?: () => any;
}

export const SelectUserModal = ({
  users,
  title = 'Select a user',
  disableFn = () => false,
  onSelect,
  action,
  loading,
  ...args
}: Props) => {
  const handleSelect = (user: User) => {
    onSelect?.(user);
  };
  return (
    <Dialog {...args}>
      <DialogTitle>{title}</DialogTitle>
      {loading ? (
        <LoadingScreen text="Loading users..." />
      ) : (
        <DialogContent>
          <List>
            {users.map((u) => (
              <UserListItem
                key={u.account_id}
                button
                hasKey={u.hasKey}
                user={u}
                disabled={disableFn(u)}
                onClick={() => handleSelect(u)}
              />
            ))}
          </List>
        </DialogContent>
      )}
      {action ? <DialogActions>{action}</DialogActions> : null}
    </Dialog>
  );
};
