import produce from 'immer';
import { API } from '../../api/API';
import { User } from '@raumplan/domain';
import { AuthAction, AuthActionTypes } from './actions';

export interface AuthState {
  token: string;
  user?: User;
  is:
    | 'logging-in'
    | 'authentication-failed'
    | 'authenticated'
    | 'not-authenticated';
  errorMessage: string;
}

export function getInitAuth(): AuthState {
  //review if browser has already login stored values
  const validState = localStorage.getItem('authToken');
  const token = localStorage.getItem('authToken') || ''; //type safe for string
  if (validState) {
    API.useBearerAuth(token);
  }
  return {
    token: validState ? token : '',
    is: validState ? 'logging-in' : 'not-authenticated',
    errorMessage: '',
  };
}

export const initState: AuthState = {
  token: '',
  user: undefined,
  is: 'not-authenticated',
  errorMessage: '',
};

export const reducer = (state: AuthState, action: AuthAction) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case AuthActionTypes.REQUEST_LOGIN: {
        draft.is = 'logging-in';
        draft.errorMessage = '';
        break;
      }
      case AuthActionTypes.LOGIN_SUCCESS: {
        draft.is = 'authenticated';
        draft.token = action.token;
        draft.user = action.user;
        break;
      }
      case AuthActionTypes.LOGIN_ERROR: {
        draft.is = 'authentication-failed';
        draft.errorMessage = action.message;
        break;
      }
      case AuthActionTypes.REQUEST_LOGOUT: {
        draft.is = 'not-authenticated';
        draft.token = '';
        draft.user = undefined;
        draft.errorMessage = '';
        break;
      }
      default:
        throw new Error('unsuported action type');
    }
  });
};
