import React from 'react';
import { Icon, makeStyles } from '@material-ui/core';
import { ReactComponent as Logo } from './qupe-logo.svg';

export const QupeLogo = () => {
  const useStyles = makeStyles((theme) => ({
    imageIcon: {
      height: '80%',
      width: '80%',
      fill: theme.palette.text.primary,
    },
    iconRoot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'inherit',
      width: 'inherit',
    },
  }));
  const classes = useStyles();
  return (
    <Icon classes={{ root: classes.iconRoot }}>
      <Logo className={classes.imageIcon} />
    </Icon>
  );
};
