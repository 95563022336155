import { Calendar } from '@raumplan/domain';

export enum CalendarActionsTypes {
  ADD = 'calendar/add',
  REMOVE = 'calendar/remove',
  SELECT = 'calendar/select',
  LOAD = 'calendars/load',
  LOAD_SUCCESSFUL = 'calendars/load/success',
  LOAD_FAIL = 'calendars/load/fail',
}

export interface AddCalendarAction {
  type: CalendarActionsTypes.ADD;
  calendar: Calendar;
}
export interface RemoveCalendarAction {
  type: CalendarActionsTypes.REMOVE;
  calendar: Calendar;
}
export interface SelectCalendarAction {
  type: CalendarActionsTypes.SELECT;
  calendar: Calendar;
}
export interface LoadCalendarsAction {
  type: CalendarActionsTypes.LOAD;
}
export interface LoadCalendarsSuccessAction {
  type: CalendarActionsTypes.LOAD_SUCCESSFUL;
  calendars: Calendar[];
}
export interface LoadCalendarsFailAction {
  type: CalendarActionsTypes.LOAD_FAIL;
  error: Error;
}

export function addCalendar(calendar: Calendar): AddCalendarAction {
  return { type: CalendarActionsTypes.ADD, calendar };
}
export function removeCalendar(calendar: Calendar): RemoveCalendarAction {
  return { type: CalendarActionsTypes.REMOVE, calendar };
}
export function selectCalendar(calendar: Calendar): SelectCalendarAction {
  return { type: CalendarActionsTypes.SELECT, calendar };
}
export function loadCalendars(): LoadCalendarsAction {
  return { type: CalendarActionsTypes.LOAD };
}
export function loadCalendarSucceeded(
  calendars: Calendar[],
): LoadCalendarsSuccessAction {
  return { type: CalendarActionsTypes.LOAD_SUCCESSFUL, calendars };
}
export function loadCalendarFailed(error: Error): LoadCalendarsFailAction {
  return { type: CalendarActionsTypes.LOAD_FAIL, error };
}
export type CalendarAction =
  | AddCalendarAction
  | RemoveCalendarAction
  | SelectCalendarAction
  | LoadCalendarsAction
  | LoadCalendarsSuccessAction
  | LoadCalendarsFailAction;
