import { User } from '@raumplan/domain';

export enum AuthActionTypes {
  REQUEST_LOGIN = 'login/request',
  LOGIN_SUCCESS = 'login/success',
  LOGIN_ERROR = 'login/error',
  REQUEST_LOGOUT = 'logout',
}

export interface RequestLoginAction {
  type: AuthActionTypes.REQUEST_LOGIN;
}
export interface LoginSuccessAction {
  type: AuthActionTypes.LOGIN_SUCCESS;
  token: string;
  user: User;
}
export interface LoginFailAction {
  type: AuthActionTypes.LOGIN_ERROR;
  message: string;
}
export interface LogoutAction {
  type: AuthActionTypes.REQUEST_LOGOUT;
}

export function requestLogin(): RequestLoginAction {
  return { type: AuthActionTypes.REQUEST_LOGIN };
}
export function loginSuccess(token: string, user: User): LoginSuccessAction {
  return { type: AuthActionTypes.LOGIN_SUCCESS, token, user };
}
export function loginFail(message: string): LoginFailAction {
  return { type: AuthActionTypes.LOGIN_ERROR, message };
}
export function requestLogout(): LogoutAction {
  return { type: AuthActionTypes.REQUEST_LOGOUT };
}

export type AuthAction =
  | RequestLoginAction
  | LoginSuccessAction
  | LoginFailAction
  | LogoutAction;
