import dayjs, { Dayjs } from 'dayjs';

/**
 * Obtain a the days of the week for a given day
 * @param date day to obtain weekdays from
 * @returns list of dayjs of the week of the given day
 */
export function getWeek(date: Dayjs): Dayjs[] {
  return daysBetween(date.day(0), date.day(6));
}

/**
 * obtain a list of Dayjs dates between given dates (these included)
 * @param from initial day
 * @param until final day
 * @returns list of dates
 */
export function daysBetween(from: Dayjs, until: Dayjs) {
  if (from.isAfter(until)) {
    [from, until] = [until, from];
  }
  if (from.isSame(until, 'day')) {
    return [];
  }
  const days = [from];
  const amount = until.diff(from, 'days');
  for (let i = 0; i < amount; i++) {
    days.push(from.add(i + 1, 'days'));
  }
  return days;
}

/**
 * Common formatter for a date. It only has numeric values DD-MM-YYYY
 * @param date
 * @returns formatted date
 */
export function formatted(date: Dayjs | Date) {
  return dayjs(date).format('DD-MM-YYYY');
}
