import React from 'react';
import dayjs from 'dayjs';
import AddIcon from '@material-ui/icons/Add';

import {
  Box,
  Button,
  Divider,
  List,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';

import { Action, Entry, User } from '@raumplan/domain';
import { UserAbilityContext } from '../../../common/context/UserAbilityProvider';
import { UserListItem } from '../UserListItem';
import { CircularProgress } from '@material-ui/core';
import { EntryState } from '../../organisms/WeekCalendar';

export type Props = {
  date: Date;
  user?: User;
  entries?: EntryState[];
  capacity?: number;
  loading?: boolean;
  onAddEntry?: (date: Date) => void;
  onEntryDelete?: (entry: Entry) => void;
};

export const Day = ({
  date: inputdate,
  entries = [],
  user,
  capacity = Infinity,
  loading = false,
  onAddEntry,
  onEntryDelete,
}: Props) => {
  const useStyle = makeStyles((theme) => ({
    root: {
      minWidth: theme.spacing(10),
    },
    header: {
      height: theme.spacing(3),
      width: theme.spacing(3),
      fontWeight: theme.typography.fontWeightBold,
    },
    today: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '100%',
      color: theme.palette.getContrastText(theme.palette.secondary.main),
    },
  }));
  const date = dayjs(inputdate);
  const today = date.isSame(dayjs(), 'day');
  const classes = useStyle();
  const handleAddEntry = (date: Date) => {
    onAddEntry?.(date);
  };
  return (
    <Box className={classes.root} display="flex" flexDirection="column">
      <Paper>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          minWidth="150px"
        >
          <Typography
            className={`${classes.header} ${today ? classes.today : ''}`}
            align="center"
          >
            {date.format('D')}
          </Typography>
        </Box>
        <Divider />
        <Box
          display="flex"
          flexDirection="column"
          flexGrow="1"
          alignContent="stretch"
        >
          <UserAbilityContext.Consumer>
            {(ability) => {
              return (
                <List>
                  {entries.map((entry) => {
                    return (
                      <UserListItem
                        key={entry.id}
                        hasKey={entry.hasKey}
                        user={entry.user}
                        disabled={date.isBefore(dayjs(), 'days')}
                        canDelete={ability.can(Action.Delete, entry)}
                        isDeleting={entry.isDeleting ? entry.isDeleting : false}
                        onDelete={() => onEntryDelete?.(entry)}
                      />
                    );
                  })}
                </List>
              );
            }}
          </UserAbilityContext.Consumer>
          {entries?.length < capacity &&
          !entries.find((e) => e.user.account_id === user?.account_id) &&
          !date.isBefore(dayjs(), 'days') ? (
            <Button
              id="add-user-button"
              aria-label="add-user"
              size="small"
              disabled={loading}
              color="secondary"
              onClick={() => handleAddEntry(date.toDate())}
            >
              {loading ? (
                <CircularProgress color="secondary" size={24} />
              ) : (
                <AddIcon />
              )}
            </Button>
          ) : null}
        </Box>
      </Paper>
    </Box>
  );
};
