import { Box, CircularProgress, Typography } from '@material-ui/core';
import React, { useEffect, useReducer } from 'react';
import { API } from 'src/common/api/API';
import { useAuth } from 'src/common/context/AuthProvider';
import { CalendarAppBar } from 'src/components/organisms/CalendarAppBar';
import { CalendarSelector } from 'src/components/organisms/CalendarSelector';
import { CalendarTemplate } from 'src/components/templates/CalendarTemplate';
import { Calendar, User } from '@raumplan/domain';
import { WeekCalendar } from '../../organisms/WeekCalendar';
import {
  addEntry,
  unselectCalendar,
  deleteEntry,
  loadCalendar,
  loadCalendarFail,
  loadCalendarSuccess,
  selectCalendar,
  userUpdated,
} from './actions';
import { reducer, initialCalendarState, Screen } from './reducer';
import SubscriptionAPI from 'src/common/api/SubscriptionAPI';

export const CalendarPage = () => {
  const [state, dispatch] = useReducer(reducer, initialCalendarState);
  const [user, authDispatch] = useAuth();
  const { current: currentCalendar, screen, error } = state;
  //generate subscription to events once calendar is changed
  useEffect(() => {
    if (!currentCalendar) return;
    return SubscriptionAPI.subscribeCalendarEvents(
      currentCalendar.id,
      user.token,
      (e) => dispatch(addEntry(e.calendarId, e.entry)),
      (e) => dispatch(deleteEntry(e.calendarId, e.id)),
    );
  }, [currentCalendar?.id]);
  //first load of calendar
  useEffect(() => {
    if (currentCalendar && screen === Screen.LOADING) {
      handleCalendarRefresh(currentCalendar);
    }
  }, [currentCalendar?.id]);
  let content = null;
  const handleCalendarRefresh = (calendar?: Calendar) => {
    if (!calendar) {
      dispatch(unselectCalendar());
      return;
    }
    dispatch(loadCalendar(calendar.id));
    API.getCalendar(
      calendar.id,
      (cal) => {
        dispatch(loadCalendarSuccess(cal));
      },
      (e) => dispatch(loadCalendarFail(calendar.id, e)),
    );
  };
  const handleUserUpdate = (u: User) => {
    if (u.account_id === user.user?.account_id) {
      authDispatch.refresh(u);
    }
    dispatch(userUpdated(u));
  };
  switch (screen) {
    case Screen.LOADED: {
      content = currentCalendar ? (
        <WeekCalendar
          id={currentCalendar.id}
          entries={currentCalendar.entries}
          currentUser={user.user}
          capacity={currentCalendar.capacity || Infinity}
          onAddEntry={(entry) => dispatch(addEntry(currentCalendar.id, entry))}
          onDeleteEntry={(entry) =>
            dispatch(deleteEntry(currentCalendar.id, entry.id))
          }
        />
      ) : null;
      break;
    }
    case Screen.LOADING: {
      content = (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <CircularProgress color="secondary" />
        </Box>
      );
      break;
    }
    case Screen.NOT_SELECTED: {
      content = (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <Typography color="secondary">No calendar selected</Typography>
        </Box>
      );
      break;
    }
    case Screen.ERROR: {
      content = (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <Typography color="secondary">
            Failed to load Calendar: {error}
          </Typography>
        </Box>
      );
      break;
    }
  }
  return (
    <CalendarTemplate
      header={
        <CalendarAppBar
          title={currentCalendar ? currentCalendar.name : 'Select a calendar 🗓'}
          user={user.user}
          onLogout={() => {
            authDispatch.logout();
            window.location.href = '/';
          }}
          onLogin={() => {
            window.location.href = '/login';
          }}
          onUserUpdate={handleUserUpdate}
        />
      }
      calendarSelector={
        <CalendarSelector
          onSelect={(cal) => dispatch(selectCalendar(cal))}
          onDelete={(cal) =>
            cal.id === currentCalendar?.id ? dispatch(unselectCalendar()) : null
          }
        />
      }
      calendar={content}
    />
  );
};
