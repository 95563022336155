import { createMuiTheme } from '@material-ui/core/styles';

export type ThemeType = 'dark' | 'light';
export const theme = (apparence?: ThemeType) => {
  return createMuiTheme({
    palette: {
      primary: {
        main: '#00838f',
      },
      secondary: {
        main: '#E85D75',
      },
      type: apparence ? apparence : 'light',
    },
  });
};
