import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useAuth } from 'src/common/context/AuthProvider';
import { LoadingScreen } from '../../../components/molecules/LoadingScreen';

export const LoginCallback = () => {
  const query = new URLSearchParams(useLocation().search);
  const [state, dispatch] = useAuth();
  useEffect(() => {
    if (state.is === 'not-authenticated') {
      dispatch.login(query.get('code'));
    }
  }, []);
  let view = null;
  switch (state.is) {
    case 'logging-in': {
      view = <LoadingScreen text="Logging in ..." />;
      break;
    }
    case 'authentication-failed': {
      view = (
        <Typography>
          Ups! Something went wrong... {state.errorMessage}
        </Typography>
      );
      break;
    }
    case 'authenticated': {
      view = <Redirect to="/" />;
      break;
    }
  }
  return view;
};
