import dayjs from 'dayjs';
import produce from 'immer';
import { UpdateAction, UpdateActionTypes } from './actions';
import { Entry } from '@raumplan/domain';

export interface IDatesState {
  dates: Date[];
  entries: Entry[];
}
export const datesInitState: IDatesState = { dates: [], entries: [] };
export const reducer = (state: IDatesState, action: UpdateAction) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case UpdateActionTypes.UPDATE_DATE: {
        draft.dates.push(action.date);
        break;
      }
      case UpdateActionTypes.UPDATE_DATE_FINISHED: {
        draft.dates = draft.dates.filter(
          (d) => !dayjs(d).isSame(action.date, 'day'),
        );
        break;
      }
      case UpdateActionTypes.UPDATE_ENTRY: {
        draft.entries.push(action.entry);
        break;
      }
      case UpdateActionTypes.UPDATE_ENTRY_FINISHED: {
        draft.entries = draft.entries.filter(
          (entry) => entry.id !== action.entry.id,
        );
        break;
      }
      default:
        throw new Error('action not supported');
    }
  });
};
