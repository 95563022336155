import { EntryEventType, EntryCreated, EntryDeleted } from '@raumplan/domain';
import { io } from 'socket.io-client';
export class SubscriptionService {
  /**
   *
   * @param id
   * @param onCreateEntry
   * @param onDeleteEntry
   * @returns disconnect method
   */
  public subscribeCalendarEvents(
    id: number,
    token: string,
    onCreateEntry?: (event: EntryCreated) => any,
    onDeleteEntry?: (event: EntryDeleted) => any,
  ) {
    const newSocket = io('/', {
      reconnectionAttempts: 5,
      auth: { token },
      query: { calendarId: `${id}` },
    });
    newSocket.on(EntryEventType.ENTRY_CREATED, (args: EntryCreated) => {
      onCreateEntry?.(args);
    });
    newSocket.on(EntryEventType.ENTRY_DELETED, (args: EntryDeleted) => {
      onDeleteEntry?.(args);
    });
    return () => {
      newSocket.disconnect();
    };
  }
}

export default new SubscriptionService();
