import jwtDecode from 'jwt-decode';

/**
 * Validates a JWT token according to qupe access rules.
 * @param token jwt to validate for access
 * @returns true if token is valid, false if empty or not valid
 */
export function validJwtToken(token: string) {
  if (!token) return false;
  try {
    jwtDecode(token);
  } catch (e) {
    return false;
  }
  return true;
}
