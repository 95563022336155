import { Box, makeStyles } from '@material-ui/core';
import React from 'react';

export type Props = {
  calendar: any;
  calendarSelector: any;
  header: any;
};

/**
 * Defines the main layout and fills it with the given components in properties.
 */
export const CalendarTemplate = ({
  header,
  calendarSelector,
  calendar,
}: Props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '100vh',
      boxSizing: 'border-box',
    },
    contentRoot: {
      display: 'flex',
      flexDirection: 'row',
      maxHeight: '90vh',
      justifyContent: 'space-between',
      alignContent: 'space-around',
    },
    header: {
      position: 'sticky',
      top: 0,
      zIndex: 999,
    },
    sideList: {
      width: '25%',
      height: 'auto',
      minWidth: '150px',
      maxHeight: 'inherit',
      padding: theme.spacing(1),
      alignItems: 'center',
      overflow: 'hidden',
    },
    content: {
      width: '70%',
      height: 'auto',
      maxHeight: 'inherit',
      padding: theme.spacing(1),
      alignItems: 'center',
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.header}>{header}</Box>
      <Box className={classes.contentRoot}>
        <Box className={classes.sideList}>{calendarSelector}</Box>
        <Box className={classes.content}>{calendar}</Box>
      </Box>
    </Box>
  );
};
