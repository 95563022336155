import {
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  root: {
    padding: theme.spacing(3, 2),
  },
}));

export interface Props {
  open: boolean;
  title: string;
  onAccept?: () => any;
  onReject?: () => any;
  onClose?: () => any;
}
export const ActionModal = ({
  open,
  title,
  onAccept,
  onReject,
  onClose,
}: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={() => onClose?.()}
      aria-labelledby="action-dialog"
    >
      <DialogTitle id="action-dialog">{title}</DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          id="accept-button"
          className={classes.button}
          onClick={() => onAccept?.()}
        >
          Accept
        </Button>
        <Button
          variant="contained"
          color="primary"
          id="reject-button"
          className={classes.button}
          onClick={() => onReject?.()}
        >
          Reject
        </Button>
      </DialogActions>
    </Dialog>
  );
};
